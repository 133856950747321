/* eslint-disable react/display-name, camelcase */
import clsx from 'clsx';
import { render } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { hasSession } from './auth/schibsted-account';
import { trackTeaser, getElementTrackingData } from './front-tracking.js';
import { trackEngagement, ready as pulseReady } from './tracking/pulse';
import { isSafari } from './utils/device';
import { addPreviewVideo } from './preview-video';
// import { trackVisibility } from './utils/trackVisibility';

const API_BASE_URL = 'https://user-segmentation-api.prod.sk8s.vg.no';

/** @type {string[]} */
const OPINION_ARTICLES = ['column', 'editorial', 'op-ed', 'review'];
const supportsCqw = CSS.supports('font-size: 1cqw');

/**
 * @typedef {{
 *  article_id: string;
 *  icon: string;
 *  id: string;
 *  lifetime: number;
 *  subTitle: string;
 *  theme: string;
 *  dice: number;
 *  links: { canonicalUrl: string };
 *  optimizedTitle: {
 *      fs: number;
 *      cqw: number;
 *      lines: Array<{ content: string; fs: number; cqw: number }>;
 *  };
 *  presentationType: string;
 *  imageAsset?: {
 *      hotspot?: { x: number; y: number; width: number; height: number };
 *      size?: { width: number; height: number };
 *      urls: { url: string; width: number; height: number }[];
 *  };
 *  published: string;
 *  sdrn: string;
 *  teaserPlacement: { id: string; row: number; width: string };
 *  title: { value: string };
 *  updated: number;
 *  weight: number;
 *  videoAsset?: {
 *    duration: number;
 *    access?: { plus: boolean; sport: boolean };
 *    series?: { seasonNumber: boolean; episodeNumber: boolean }
 *    metadata: { contentType?: string; preview_vivi_category: string };
 *    category?: { id: number; parentId?: number };
 *  };
 *  _rank?: number;
 * }} PersonalizationItem
 */

/**
 * @param {string} selector
 * @returns {HTMLElement}
 */
function querySelectorLast(selector) {
    /** @type {NodeListOf<HTMLElement>} */
    const elms = document.querySelectorAll(selector);
    return elms[elms.length - 1];
}

/**
 * @returns {HTMLElement}
 */
function createExtraVgtvPlacement(teaserPlacement) {
    const { id, row, position } = teaserPlacement;
    let insertAfter = querySelectorLast(
        `#hovedlopet article[data-row="${row}"]`,
    );
    if (!insertAfter) {
        return;
    }
    const insertAfterClosestPackage = insertAfter.closest('.container-package');
    if (insertAfterClosestPackage) {
        insertAfter = insertAfterClosestPackage;
    }

    const teaserElm = document.createElement('article');
    teaserElm.id = id;
    teaserElm.className = `article article-width-${teaserPlacement.width} extra-vgtv`;
    teaserElm.dataset.row = row;
    // @ts-ignore
    teaserElm.trackingData = {
        row,
        position,
    };
    insertAfter.after(teaserElm);
    return teaserElm;
}

/**
 * @param {PersonalizationItem["imageAsset"]} imageAsset
 * @returns {string}
 */
const makeHotspot = ({ hotspot, size }, heightReduction) =>
    size &&
    hotspot &&
    [
        (hotspot.x + hotspot.width / 2) / size.width,
        ((hotspot.y + hotspot.height / 2) * heightReduction) /
            (size.height * heightReduction),
    ]
        .map((num) => num * 100 + '%')
        .join(' ');

const TEASER_SIZE_MAPPING = {
    full: 1,
    half: 0.5,
    'one-third': 0.33,
    'two-third': 0.66,
    'two-fifth': 0.4,
    'three-fifth': 0.6,
};

/**
 * @param {string | number} duration
 */
function formatDuration(duration) {
    const date = new Date(parseInt(duration));
    return Intl.DateTimeFormat('nb-NO', {
        minute: 'numeric',
        second: 'numeric',
    }).format(date);
}

/** @type {Record<string, import('preact').FunctionComponent>} */
const TopicStickers = {
    evergreen: () => (
        <div class="topic-sticker">
            <img
                src="gfx/icons/topic-evergreen.svg"
                loading="lazy"
                aria-label="Lest denne?"
                width="22"
                height="22"
            />
            <span>Lest denne?</span>
        </div>
    ),
    column: () => (
        <div class="topic-sticker">
            <img
                src="gfx/icons/meninger.svg"
                loading="lazy"
                aria-label="Kommentar"
                width="22"
                height="22"
            />
            <span>Kommentar</span>
        </div>
    ),
    review: ({ dice }) => (
        <div class="topic-sticker">
            {dice && (
                <img
                    class="dice"
                    src={`https://www.vg.no/vgc/frimand/gfx/icons/old-dice-${dice}.svg`}
                    loading="lazy"
                    aria-label={`Terningskast ${dice}`}
                    width="22"
                    height="22"
                />
            )}
            <span>Anmeldt</span>
        </div>
    ),
    editorial: () => (
        <div class="topic-sticker">
            <img
                src="gfx/icons/meninger.svg"
                loading="lazy"
                aria-label="Kommentar"
                width="22"
                height="22"
            />
            <span>VG Mener</span>
        </div>
    ),
};
const TopicSticker = ({ theme, dice }) => {
    const Component = TopicStickers[theme];
    return Component ? <Component dice={dice} /> : null;
};

/** @type {Record<string, import('preact').FunctionComponent>} */
const Icons = {
    pluss: () => (
        <img
            src="gfx/icons/pluss.svg"
            loading="lazy"
            alt="VG+"
            width="24"
            height="24"
            aria-label="VG pluss"
        />
    ),
    vgtv_pluss: () => (
        <img
            src="gfx/icons/vgtv_pluss.svg"
            loading="lazy"
            alt="VG+"
            width="24"
            height="24"
            aria-label="VGTV pluss"
        />
    ),
    vgtv: ({ videoAsset }) => (
        <>
            <img
                src="gfx/icons/vgtv_play_simple.svg"
                loading="lazy"
                alt="VGTV"
                width="24"
                height="24"
                aria-label="VGTV"
            />
            {videoAsset?.duration > 0 && (
                <span class="duration">
                    {formatDuration(videoAsset.duration)}
                </span>
            )}
        </>
    ),
    'pluss-sport': () => (
        <>
            <img
                src="gfx/icons/pluss.svg"
                loading="lazy"
                alt="VG+"
                width="24"
                height="24"
                aria-label="VG Pluss Sport"
            />
            {' '}SPORT
        </>
    ),
    e24: () => (
        <img
            src="gfx/icons/e24.svg"
            loading="lazy"
            alt="E24"
            width="24"
            height="24"
            aria-label="E24"
        />
    ),
};
const Icon = ({ icon, videoAsset }) => {
    const Component = Icons[icon];
    return Component ? <Component videoAsset={videoAsset} /> : null;
};

/**
 * @param {object} props
 * @param {string} props.videoPreviewUrl
 * @returns {JSX.Element}
 */
const VideoPreview = ({ videoPreviewUrl }) => {
    const videoPreviewRef = useRef();

    useEffect(() => {
        if (!videoPreviewRef.current) {
            return;
        }

        addPreviewVideo(videoPreviewRef.current);
    }, [videoPreviewRef.current]);

    if (isSafari()) {
        return (
            <img
                ref={videoPreviewRef}
                src={videoPreviewUrl}
                class="preview-video safari-only"
                loading="lazy"
            />
        );
    }
    return (
        <video
            ref={videoPreviewRef}
            class="preview-video"
            loading="lazy"
            preload="none"
            autoPlay
            playsInline
            muted
            loop
            disableRemotePlayback
            src={videoPreviewUrl}
        />
    );
};

/**
 * @param {object} props
 * @param {PersonalizationItem["imageAsset"]} [props.imageAsset]
 * @param {string} props.icon
 * @param {PersonalizationItem['videoAsset']} [props.videoAsset]
 * @param {string} props.size
 * @returns {JSX.Element}
 */
const TeaserImage = ({ imageAsset, size, icon, videoAsset }) => {
    const src = imageAsset.urls[imageAsset.urls.length - 1].url;
    const heightReduction = size === 'full' ? 1 : 0.75;
    const srcSet = imageAsset.urls
        .map(({ url, width }) => `${url} ${width}w`)
        .join(', ');
    const imageSize =
        imageAsset.size || imageAsset.urls[imageAsset.urls.length - 1];
    const aspectRatio = `${imageSize.width} / ${
        imageSize.height * heightReduction
    }`;
    const objectPosition = makeHotspot(imageAsset, heightReduction);
    const sizes = `(min-width: 654px) ${654 * TEASER_SIZE_MAPPING[size]}px, ${
        TEASER_SIZE_MAPPING[size] * 100
    }vw`;
    return (
        <figure>
            <div
                style={{
                    aspectRatio: aspectRatio || '16/9',
                }}
            >
                <img
                    class="article-image"
                    srcSet={srcSet}
                    src={src}
                    sizes={sizes}
                    style={
                        objectPosition && {
                            objectPosition,
                        }
                    }
                />
                {videoAsset?.metadata?.preview_vivi_category && (
                    <VideoPreview
                        videoPreviewUrl={
                            videoAsset.metadata.preview_vivi_category
                        }
                    />
                )}
                {icon && icon != 'default' && (
                    <div className="type-icon-wrapper">
                        <div class="type-icon">
                            <Icon icon={icon} videoAsset={videoAsset} />
                        </div>
                    </div>
                )}
            </div>
        </figure>
    );
};

/**
 * @param {object} props
 * @param {PersonalizationItem["optimizedTitle"]} props.optimizedTitle
 * @param {boolean} props.titlewrap
 * @returns {JSX.Element}
 */
const OptimizedTitle = ({ optimizedTitle, titlewrap }) => {
    const headlineStyles = {
        fontSize: supportsCqw
            ? `${optimizedTitle.cqw}cqw`
            : `${optimizedTitle.fs / 16}rem`,
    };

    return (
        <h2 className="headline" style={headlineStyles}>
            {optimizedTitle.lines.map((line, i) => (
                <span
                    key={i}
                    className={clsx(
                        'd-block',
                        titlewrap && !line.fs && 'titlewrap',
                    )}
                    style={
                        line.fs
                            ? {
                                  fontSize: supportsCqw
                                      ? `${line.cqw}cqw`
                                      : `${line.fs / 16}rem;`,
                              }
                            : {}
                    }
                >
                    {line.content}
                </span>
            ))}
        </h2>
    );
};

/**
 * @param {PersonalizationItem['videoAsset']} videoAsset
 * @returns {boolean}
 */
const shouldUseOverlay = (videoAsset) => {
    if (!videoAsset) {
        return false;
    }

    if (videoAsset.series) {
        return false;
    }

    const categories = [videoAsset.category?.id, videoAsset.category?.parentId];

    if (
        [
            121, // Dokumentar
        ].some((categoryId) => categories.includes(categoryId))
    ) {
        return false;
    }

    return true;
};

const hasPaywall = (videoAsset) => {
    if (!videoAsset) {
        return false;
    }

    const access = videoAsset.access ?? {};

    return Object.keys(access).length > 0;
};

/**
 * @param {object} props
 * @param {PersonalizationItem["optimizedTitle"]} props.optimizedTitle
 * @param {string} [props.subTitle]
 * @param {string} [props.className]
 * @param {string} props.href
 * @param {PersonalizationItem["imageAsset"]} [props.imageAsset]
 * @param {string} props.theme
 * @param {string} props.icon
 * @param {number} props.dice
 * @param {PersonalizationItem['videoAsset']} props.videoAsset
 * @param {string} props.size
 * @param {boolean} props.floatingImage
 * @param {number} props.row
 * @returns {JSX.Element}
 */
export const Teaser = ({
    optimizedTitle,
    subTitle,
    className = '',
    href,
    imageAsset,
    theme,
    icon,
    dice,
    videoAsset,
    size,
    floatingImage,
    row,
}) => (
    <article
        className={clsx(
            'article article-extract newsroom-vg',
            className,
            imageAsset && 'has-article-image has-full-width-image',
            OPINION_ARTICLES.includes(theme) && 'text-center',
            floatingImage && 'has-floating-image',
        )}
        data-row={row}
        data-paywall={hasPaywall(videoAsset) ? true : null}
        // this needs to match this logic https://github.schibsted.io/vg/frimand/blob/581b9acc35a673c3be748776826be6903b020a90/resources/views/front/article.blade.php#L17
        data-vivi={shouldUseOverlay(videoAsset) ? null : false}
    >
        <div className="article-container">
            <a href={href}>
                {imageAsset ? (
                    <TeaserImage
                        imageAsset={imageAsset}
                        size={size}
                        icon={icon}
                        videoAsset={videoAsset}
                    />
                ) : null}
                <div className="titles">
                    <TopicSticker theme={theme} dice={dice} />
                    {subTitle && subTitle != 'NA' ? (
                        <span className="insert-title">{subTitle}</span>
                    ) : null}
                    <OptimizedTitle
                        optimizedTitle={optimizedTitle}
                        titlewrap={OPINION_ARTICLES.includes(theme)}
                    />
                </div>
            </a>
        </div>
    </article>
);

/**
 * @param {string} sdrn
 * @returns {string}
 */
function newsroomFromSdrn(sdrn) {
    return sdrn.split(':')[0];
}

/**
 * @param {string} sdrn
 * @returns {string | null}
 */
const extractIdFromSdrn = (sdrn) => {
    if (!sdrn) {
        return null;
    }

    const splitted = sdrn.split(':');
    return splitted[splitted.length - 1];
};

/**
 * replaces teaserElm with a new teaser (made from personalizedItems)
 *
 * @param {PersonalizationItem} personalizedItem
 * @param {HTMLElement} teaserElm
 * @param {string} [variant]
 * @returns {HTMLElement}
 */
const replaceTeaserWithPersonalizedTeaser = (
    personalizedItem,
    teaserElm,
    variant,
) => {
    const {
        id,
        sdrn,
        subTitle,
        theme,
        icon,
        dice,
        imageAsset = null,
        title: { value: titleValue } = {},
        links: { canonicalUrl },
        optimizedTitle,
        videoAsset,
    } = personalizedItem;
    const queryParams = new URLSearchParams(
        teaserElm.querySelector('a')?.search,
    );
    queryParams.set('utm_term', variant);
    const placementSize = (/\barticle-width-(\S+)/.exec(teaserElm.className) ||
        [])[1];
    const title = titleValue;
    // render into an empty div, since replaceNode is deprecated in preact
    const containerElm = document.createElement('div');
    const sdrnId = extractIdFromSdrn(sdrn);
    let floatingImage = false;
    if (supportsCqw && placementSize === 'full') {
        // if either largest image is too small or too high
        const largestImage = imageAsset.urls[imageAsset.urls.length - 1];
        if (largestImage.width < 600) {
            floatingImage = true;
        } else if (largestImage.height > largestImage.width) {
            floatingImage = true;
        }
    }
    let href = `${canonicalUrl}?utm_source=vgfront-personalized&utm_content=${queryParams.get(
        'utm_content',
    )}&utm_term=${queryParams.get('utm_term')}`;
    if (href.includes('/dinepenger')) {
        href += '&vgfront=true';
    }

    render(
        <Teaser
            className={`article-width-${placementSize}`}
            optimizedTitle={optimizedTitle}
            subTitle={subTitle}
            imageAsset={imageAsset}
            href={href}
            theme={theme}
            icon={icon}
            dice={dice}
            videoAsset={videoAsset}
            size={placementSize}
            floatingImage={floatingImage}
            row={personalizedItem.teaserPlacement.row}
        />,
        containerElm,
    );
    const newTeaserElm = containerElm.firstElementChild;
    const oldTrackingData = getElementTrackingData(teaserElm);
    if (teaserElm.style.gridRow) {
        // copy over any gridRow style (tower teaser)
        newTeaserElm.style.gridRow = teaserElm.style.gridRow;
    }
    teaserElm.replaceWith(newTeaserElm);
    newTeaserElm.trackingData = {
        articleId: sdrnId ?? id,
        row: oldTrackingData.row,
        position: oldTrackingData.position,
        brand: newsroomFromSdrn(sdrn),
        size: TEASER_SIZE_MAPPING[placementSize],
        skin: 'default',
        skinIcon: icon,
        teaserText: title,
    };
    trackTeaser(newTeaserElm);
    return newTeaserElm;
};

/** @type {Record<string,string>} */
const TRACKING_PREFIXES = {
    pluss: 'vgpluss',
    niche: 'vg-niche',
    vgtv: 'vgtv',
    e24: 'e24',
};

export default async function personalizedVgpluss(optionsOrVariant = {}) {
    if (typeof optionsOrVariant === 'string') {
        optionsOrVariant = {
            variant: optionsOrVariant,
        };
    }
    const {
        variant = 'a',
        tag = 'pluss',
        service = 'vgpluss-personalized-front',
    } = optionsOrVariant;
    const isControl = variant.includes('control');

    const sig = await hasSession()
        .then((sess) => sess.sig)
        .catch(() => null);

    let environmentId;
    if (!sig) {
        environmentId = await pulseReady().then((sdk) =>
            sdk.getEnvironmentId(),
        );
    }

    if (!sig && !environmentId) {
        return;
    }

    /** @type {HTMLElement[]} */
    const teasers = $$(`#hovedlopet article.personalized--${tag}`).filter(
        (elm) =>
            !elm.matches('.container-package *, #sport-on-front *') &&
            !elm.hidden &&
            !elm.style.gridRow,
    );

    const trackingPrefix = TRACKING_PREFIXES[tag];
    let strategyInfo = {
        strategyName: 'baseline',
        strategyVersion: 'v0',
        trackingTag: '0',
    };
    const initialInScreenObserver = new IntersectionObserver((entries) => {
        const firstInScreen = entries.find((entry) => entry.isIntersecting);
        if (!firstInScreen) {
            return;
        }
        initialInScreenObserver.disconnect();
        pulseReady().then((sdk) => {
            const experiment = [
                {
                    id: `${trackingPrefix}-personalized-${strategyInfo.strategyName}-${strategyInfo.strategyVersion}-${strategyInfo.trackingTag}`,
                    platform: 'vg',
                },
            ];
            const experiments = [
                ...(sdk.builders.experiments || []),
                ...experiment,
            ];
            sdk.update({
                experiments,
            });
        });
    });

    /*
    const teasersPostData = teasers.map((teaserElm) => ({
        id: teaserElm.id,
        row: getElementTrackingData(teaserElm)?.row,
        width: (/article-width-(\S+)/.exec(teaserElm.className) || [])[1],
    }));
    */

    const frontVersionId = document
        .querySelector('#hovedlopet')
        .getAttribute('data-version');
    const qs = new URLSearchParams({
        limit: String(teasers.length),
        frontVersionId,
    });
    for (const key of ['feedId', 'subscriptionFeedId']) {
        const value = optionsOrVariant[key];
        if (value) {
            qs.set(key, value);
        }
    }
    const reqHeaders = {
        // 'content-type': 'application/json',
    };
    if (sig) {
        reqHeaders['authorization'] = `Bearer ${sig}`;
    }
    const personalizedItemsRes = await window.fetch(
        `${API_BASE_URL}/v1/service/${service}?${qs.toString()}`,
        {
            credentials: 'include',
            headers: reqHeaders,
            method: 'GET',
            // body: JSON.stringify({ teasers: teasersPostData }),
        },
    );
    if (!personalizedItemsRes.ok && !isControl) {
        console.error(
            new Error(
                `Personalized vgpluss returned status ${personalizedItemsRes.status}`,
            ),
        );
        return;
    }

    /** @type {{
     * items: PersonalizationItem[];
     * strategyInfo: Record<string, string>;
     * errorReason?: string;
     * maxRank?: number;
     * }} */
    const result = await personalizedItemsRes.json();
    const personalizedItems = Array.isArray(result) ? result : result.items;
    const personalizedItemsIterator = personalizedItems.values();
    strategyInfo = result.strategyInfo || {
        strategyName: personalizedItemsRes.headers.get('x-strategy-name'),
        strategyVersion: personalizedItemsRes.headers.get('x-strategy-version'),
        trackingTag: personalizedItemsRes.headers.get('x-tracking-tag'),
    };

    if (isControl || !personalizedItems.length) {
        // Track personalization initialization
        trackEngagement({
            elementId: `${trackingPrefix}-personalized-${variant}`,
            elementType: `${trackingPrefix}-hovedlopet`,
            action: 'Scroll',
            intent: 'View',
            custom: {
                strategyInfo,
                teasersAsk: teasers.length,
                teasersResult: personalizedItems.length,
                errorReason: result.errorReason,
                maxRank: result.maxRank,
            },
        });
        if (isControl) {
            teasers.forEach((elm) => {
                initialInScreenObserver.observe(elm);
                const linkElm = elm.querySelector('a');
                const searchParams = new URLSearchParams(linkElm.search);
                searchParams.set('utm_term', variant);
                linkElm.search = searchParams.toString();
            });
        }
        return;
    }

    const windowHeight = window.innerHeight;

    let skipInscreenCounter = 0;

    for (const personalizedItem of personalizedItemsIterator) {
        const { teaserPlacement } = personalizedItem;
        let teaserElm = document.getElementById(teaserPlacement.id);
        if (teaserPlacement.id.startsWith('vgtv-extra')) {
            teaserElm = createExtraVgtvPlacement(teaserPlacement);
        }

        if (!teaserElm) {
            console.warn(
                new Error(`Could not find teaser ${teaserPlacement.id}`),
            );
            continue;
        }

        if (teaserElm.hidden || teaserElm.style.gridRow) {
            continue;
        }

        const item = personalizedItem;
        /*
        const { value: item, done } = personalizedItemsIterator.next();
        if (done) {
            break;
        }
        */
        const { top, bottom } = teaserElm.getBoundingClientRect();
        if (
            bottom > 0 &&
            top < windowHeight &&
            // skip skip in-screen check for extra vgtv row
            !teaserPlacement.id.startsWith('vgtv-extra')
        ) {
            console.warn(
                new Error(
                    `SKIPPING PERSONALIZING ${teaserElm.id} (${item.id})`,
                ),
            );
            // console.log(teaserElm, item);
            // skip teasers in-screen
            skipInscreenCounter++;
            continue;
        }
        const newTeaserElm = replaceTeaserWithPersonalizedTeaser(
            item,
            teaserElm,
            `${variant}-${strategyInfo.strategyName}-${
                strategyInfo.strategyVersion
            }-${teaserPlacement.trackingTag || strategyInfo.trackingTag}`,
        );
        // @ts-ignore
        newTeaserElm.trackingData.drFrontId = `${trackingPrefix}-personalized-${
            strategyInfo.strategyName
        }-${strategyInfo.strategyVersion}-${
            teaserPlacement.trackingTag || strategyInfo.trackingTag
        }`;
        /*
        // @ts-ignore
        newTeaserElm.trackingData.personalization = {
            prefix: trackingPrefix,
            strategyName: strategyInfo.strategyName,
            strategyVersion: strategyInfo.strategyVersion,
            trackingTag: strategyInfo.trackingTag,
        };
        */
        initialInScreenObserver.observe(newTeaserElm);
    }

    // Track personalization initialization
    trackEngagement({
        elementId: `${trackingPrefix}-personalized-${variant}`,
        elementType: `${trackingPrefix}-hovedlopet`,
        action: 'Scroll',
        intent: 'View',
        custom: {
            strategyInfo,
            skipInscreenCounter,
            teasersAsk: teasers.length,
            teasersResult: personalizedItems.length,
            errorReason: result.errorReason,
            maxRank: result.maxRank,
            frontVersionId,
        },
    });
}
